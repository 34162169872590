import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const PrivateRoute = ({children, ...rest}) => {
  const isAuth = !!sessionStorage.getItem('token')

  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: {from: location},
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
