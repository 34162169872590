import React from 'react'
import {Container} from 'reactstrap'
import {useStoreActions} from 'easy-peasy'

import {NavMenu} from 'components/NavMenu'

const Layout = ({children}) => {
  const setModules = useStoreActions((actions) => actions.modulesLoad)
  setModules()

  return (
    <div>
      <NavMenu />
      <Container>{children}</Container>
    </div>
  )
}

// Layout.displayName = Layout.name

export default Layout
