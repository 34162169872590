import {createStore, action, thunk, computed} from 'easy-peasy'
import getAllModules from 'features/home/get-all-modules'

const store = createStore({
  modules: {items: []},

  modulesLoaded: action((state, payload) => {
    state.modules = payload
  }),

  modulesLoad: thunk(async (actions) => {
    const modules = await getAllModules()
    actions.modulesLoaded(modules)

    return modules
  }),

  setCheckbox: action((state, payload) => {
    const modId = payload.moduleId
    const checkId = payload.checkboxId

    const itms = JSON.parse(JSON.stringify(state.modules.items))
    for (let m of itms) {
      if (m.id === modId) {
        for (let c of m.checklist) {
          if (c.id === checkId) {
            c.checked = !c.checked
          }
        }
      }
    }

    state.modules = {items: itms}
  }),

  setStatus: action((state, payload) => {
    const moduleId = payload.moduleId
    // dictionary [courseId, status]
    const coursesDict = payload.courses

    const ch = state.modules.items.find((x) => x.id === moduleId).checklist
    ch.forEach((x) => {
      x.status = coursesDict[x.id]
    })
  }),

  selectedCourses: computed((state) => (moduleId) => {
    const found = state.modules.items.find((x) => x.id === moduleId)
    const selected = (found && found.checklist) || []

    return selected.filter((x) => !!x.checked).map((x) => x.id)
  }),
})

export default store
