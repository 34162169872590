import React from 'react'
import PropTypes from 'prop-types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCaretRight, faCaretDown} from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const Header = ({item, onClick}) => {
  const icon = item.isActive === 'show' ? faCaretDown : faCaretRight

  return (
    <Main
      className='card-header'
      id='headingOne'
      onClick={() => onClick(item.id)}>
      <h5 className='mb-0'>
        <Module className='btn' type='button'>
          <b>{item.moduleName}</b>
        </Module>
      </h5>
      <FontAwesomeIcon icon={icon} size='2x' />
    </Main>
  )
}

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`

const Module = styled.div`
  width: 100%;
  text-align: left;
`

Header.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    moduleName: PropTypes.string.isRequired,
    isActive: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
}

Header.defaultProps = {
  isActive: null,
}

export default Header
