import React, {Component} from 'react'
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap'
import {Link} from 'react-router-dom'
import './NavMenu.css'

export class NavMenu extends Component {
  static displayName = NavMenu.name

  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true,
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    const userName = sessionStorage.getItem('user')
    const user = sessionStorage.getItem('user').toLowerCase()

    return (
      <header>
        <Navbar
          className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3'
          light>
          <Container>
            <NavbarBrand tag={Link} to='/'>
              Scorm Uploader
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className='mr-2' />
            <Collapse
              className='d-sm-inline-flex flex-sm-row-reverse'
              isOpen={!this.state.collapsed}
              navbar>
              <ul className='navbar-nav flex-grow'>
                <div className='mr-5'>
                  <a target='_blank' href='https://adjusterpro.monday.com/boards/2135485020/views/68304088'>View "Do Not Upload" List</a>
                </div>

                {userName ? (
                  <Link to='/auth/login'> {`Hello, ${userName}!`}</Link>
                ) : (
                  <Link to='/auth/login'>Login</Link>
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}
