import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom'
import {ToastProvider} from 'react-toast-notifications'
import {StoreProvider} from 'easy-peasy'

import './custom.css'

import Layout from 'layouts/Layout'
import Home from 'pages/Home'
import LoginPage from 'pages/Login'
import RegisterPage from 'pages/RegisterPage'
import NotFound from 'pages/NotFound'
import PrivateRoute from 'components/PrivateRoute'

import store from 'features/store'

const App = () => {
  return (
    <StoreProvider store={store}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement='bottom-center'>
        <Router>
          <Switch>
            <PrivateRoute exact path='/'>
              <Layout>
                <Home />
              </Layout>
            </PrivateRoute>
            <Route path={'/auth/login'}>
              <LoginPage />
            </Route>
            <Route path={'/auth/register'}>
              <RegisterPage />
            </Route>
            <Route path={'/not-found'}>
              <NotFound />
            </Route>
            <Redirect to={'/not-found'}>
              <NotFound />
            </Redirect>
          </Switch>
        </Router>
      </ToastProvider>
    </StoreProvider>
  )
}

export default App
