import React, {useState} from 'react'
import PropTypes from 'prop-types'
import axios from 'AxiosInstance'
import Request from 'axios-request-handler'

import styled from 'styled-components'
import {useDropzone} from 'react-dropzone'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons'

import fileStatuses from 'features/uploader/fileStatuses'
import colors from 'styles/colors'

import ProgressBar from 'atomic/atm-progress-bar'

const Upload = ({moduleId, courses, onStatus}) => {
  const [revertProgress, setRevertProgress] = useState(false)
  const [percentage, setPercentage] = useState(0)
  const [fileName, setFileName] = useState('')
  const [fileStatus, setFileStatus] = useState(fileStatuses.NONE)
  const [files, setFiles] = useState([])

  const options = {
    onUploadProgress: (progressEvent) => {
      const {loaded, total} = progressEvent
      const percent = Math.floor((loaded * 100) / total)

      if (percent < 100) {
        setPercentage(percent)
      }
    },
  }

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles)
    setFileName(acceptedFiles[0].name)
    setFileStatus(fileStatuses.READY_TO_UPLOAD)
    setPercentage(0)
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false,
  })

  const onClickHandler = async (e) => {
    e.preventDefault()

    setFileStatus(fileStatuses.UPLOADING)

    const form = new FormData()
    form.append('scorm_module_id', moduleId)
    form.append('scorm_page_urls', JSON.stringify(courses()))
    form.append('revert_progress', revertProgress)
    form.append('file', files[0])

    const x = await axios.post('api/modules', form, options)

    setPercentage(100)
    setFileStatus(fileStatuses.PENDING)

    const id = JSON.parse(x.data.id).upload_id

    const reviews = new Request(`api/modules/poll/${id}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    })

    reviews.poll(2000).get((response) => {
      const res = response.data
      setFileStatus(
        `Pending: ${res.pending.length}, Failed: ${res.failed.length}, Uploaded: ${res.uploaded.length}`,
      )
      onStatus(res, moduleId)
      if (res.pending.length === 0) {
        setFileStatus(
          `Failed: ${res.failed.length}, Succeed: ${res.uploaded.length}`,
        )
        onStatus(res, moduleId)
        return false
      }
    })
  }

  return (
    <div>
      <Main className='pl-2 pt-2 pb-2' {...getRootProps()}>
        <FontAwesomeIcon
          color={colors.blue}
          icon={faCloudUploadAlt}
          size='5x'
        />
        <input {...getInputProps()} />
        <Dropzone className='ml-5 center-h'>
          {isDragActive ? (
            <div>Drop the files here...</div>
          ) : !fileStatus ? (
            <div>Drag'n'drop here or click and select a file to upload.</div>
          ) : (
            <ProgressBar percentage={percentage} fileStatus={fileStatus} />
          )}
        </Dropzone>
      </Main>
      <Action>
        <div>
          <button
            disabled={fileStatus !== 'Ready to upload.'}
            type='button'
            className='mt-2 btn btn-primary btn-small'
            onClick={onClickHandler}>
            Start uploading
          </button>

          <div className='form-check'>
            <input
              type='checkbox'
              className='form-check-input'
              id={moduleId}
              checked={revertProgress}
              onChange={() => setRevertProgress(!revertProgress)}
            />
            <label className='form-check-label' htmlFor={moduleId}>
              Revert progress and display changes for all learners
            </label>
          </div>
        </div>
        {fileName && <div>{`${fileName}`}</div>}
      </Action>
    </div>
  )
}

const Action = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  button + div {
    margin-left: 20px;
  }

  > div:first-child {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: 'center';
  border: 1px dashed ${colors.blue};
  border-radius: 5px;
`
const Dropzone = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

Upload.propTypes = {
  moduleId: PropTypes.string.isRequired,
  courses: PropTypes.func,
  onStatus: PropTypes.func,
  onRevertProgressChange: PropTypes.func,
}

Upload.defaultProps = {
  courses: () => {},
  onStatus: () => {},
  onRevertProgressChange: () => {},
}

export default Upload
