import React from 'react'
import {useStoreState, useStoreActions} from 'easy-peasy'

import uploadStatuses from 'features/uploader/uploadStatuses'

import Header from 'components/Header'
import Body from 'components/Body'
import Upload from 'components/Upload'

const Home = () => {
  const modules = useStoreState((state) => state.modules)
  const selectedCourses = useStoreState((state) => state.selectedCourses)
  const setModules = useStoreActions((actions) => actions.modulesLoaded)
  const setCheckbox = useStoreActions((actions) => actions.setCheckbox)
  const setStatus = useStoreActions((actions) => actions.setStatus)

  const onStatusHandler = (data, moduleId) => {
    const dict = {}
    data.failed.forEach((x) => (dict[x] = uploadStatuses.FAILED))
    data.pending.forEach((x) => (dict[x] = uploadStatuses.PENDING))
    data.uploaded.forEach((x) => (dict[x] = uploadStatuses.UPLOADED))
    setStatus({moduleId: moduleId, courses: dict})
  }

  const headerClickHandler = (id) => {
    const items = [...modules.items]
    items.forEach((i) => {
      i.isActive =
        i.id === id ? 'show' : i.isActive === 'show' ? '' : i.isActive
    })

    setModules({
      items: items,
    })
  }

  return (
    <div>
      <h2>SCORM Modules</h2>
      <div id='accordionExample'>
        {modules &&
          modules.items &&
          modules.items.map((itm) => {
            return (
              <div key={itm.id} className='card z-depth-0 bordered'>
                <Header item={itm} onClick={headerClickHandler} />
                <div
                  id='collapseOne'
                  className={`collapse ${itm.isActive}`}
                  aria-labelledby='headingOne'>
                  {itm && itm.isActive === 'show' && (
                    <>
                      <div className='mb-4 ml-5 mr-5'>
                        <Upload
                          moduleId={itm.id}
                          courses={() => selectedCourses(itm.id)}
                          onStatus={onStatusHandler}
                        />
                      </div>
                      <Body
                        checkList={itm.checklist}
                        onCheckListChange={(checkboxId) => {
                          setCheckbox({
                            moduleId: itm.id,
                            checkboxId: checkboxId,
                          })
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Home
