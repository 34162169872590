import axios from 'AxiosInstance'
import routes from 'features/routes'

export default () => {
  return axios
    .get(routes.apiModules)
    .then((res) => res.data)
    .then((data) => {
      const items = data.map((module, index) => {
        return {
          id: module.module_id,
          moduleName: module.module_title,
          isActive: index === 0 ? 'show' : '',
          checklist: module.courses.map((course) => {
            return {
              id: course.scorm_page_url,
              name: course.course_title,
              checked: true,
            }
          }),
        }
      })
      return {items}
    })
    .catch((x) =>
      console.error('Error during getting modules from the server: ', x),
    )
}
