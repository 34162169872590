import React from 'react'
import P from 'prop-types'

import fileStatuses from 'features/uploader/fileStatuses'

const ProgressBar = ({percentage, fileStatus}) => {
  return (
    <div className='progress' style={{minWidth: '400px'}}>
      <div
        className='progress-bar'
        role='progressbar'
        style={{width: `${percentage}%`}}
        aria-valuenow={percentage}
        aria-valuemin='0'
        aria-valuemax='100'>
        {fileStatus === fileStatuses.UPLOADING ? (
          `${percentage}%`
        ) : fileStatus === fileStatuses.PENDING ? (
          <div className='loading'>{fileStatus}</div>
        ) : (
          `${fileStatus}`
        )}
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  percentage: P.number.isRequired,
  fileStatus: P.string.isRequired,
}

export default ProgressBar
