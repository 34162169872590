import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import postLogin from 'features/login/post-login'
import {useToasts} from 'react-toast-notifications'

const LoginPage = (props) => {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const {addToast} = useToasts()

  const saveSession = (token, user) => {
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('user', user)
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()

    postLogin(login.trim(), password)
      .then(({data}) => {
        saveSession(data.token, login.trim())

        history.push('/')
      })
      .catch((x) => {
        setLogin('')
        setPassword('')

        saveSession('', '')

        console.error('Authentication Error.', props)

        addToast('Wrong User name or Password', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  return (
    <div className='container mt-5'>
      <div className='row mt-5'>
        <div className='col-sm-9 col-md-7 col-lg-5 mx-auto'>
          <div className='card card-signin my-5'>
            <div className='card-body'>
              <h5 className='card-title text-center pb-5 mt-3'>Sign In</h5>
              <form onSubmit={onSubmitHandler} className='form-signin'>
                <div className='form-label-group mb-2'>
                  <input
                    type='text'
                    id='userName'
                    className='form-control'
                    placeholder='User Name'
                    required
                    autoFocus
                    value={login}
                    onChange={(e) => setLogin(e.currentTarget.value)}
                  />
                </div>

                <div className='form-label-group  mb-4'>
                  <input
                    type='password'
                    id='inputPassword'
                    className='form-control'
                    placeholder='Password'
                    required
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </div>

                <button
                  className='btn btn-md btn-primary btn-block text-uppercase mb-3'
                  type='submit'>
                  CONTINUE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
