import Axios from 'axios'

const axiosInstance = Axios.create({
  baseURL: '/',
})

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
  return config
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    console.warn('ERROR', err)
  },
)

export default axiosInstance
