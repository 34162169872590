import React from 'react'
import P from 'prop-types'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faSync,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons'

import colors from 'styles/colors'
import uploadStatuses from 'features/uploader/uploadStatuses'

const Body = ({checkList, onCheckListChange}) => {
  const onCheckBoxChangeHandler = (e) => onCheckListChange(e.currentTarget.id)

  return (
    <div className='card-body ml-5'>
      {checkList &&
        checkList.map((checkbox) => {
          return (
            <CheckBoxes
              key={checkbox.id}
              className='custom-control custom-checkbox'>
              <div style={{minWidth: 50}}>
                {checkbox.status === uploadStatuses.FAILED ? (
                  <FontAwesomeIcon color='red' icon={faThumbsDown} />
                ) : checkbox.status === uploadStatuses.PENDING ? (
                  <FontAwesomeIcon color={colors.blue} icon={faSync} />
                ) : checkbox.status === uploadStatuses.UPLOADED ? (
                  <FontAwesomeIcon color='green' icon={faThumbsUp} />
                ) : null}
              </div>
              <div>
                <input
                  className='custom-control-input'
                  id={`${checkbox.id}`}
                  type='checkbox'
                  readOnly
                  checked={checkbox.checked}
                  onChange={onCheckBoxChangeHandler}
                />
                <label
                  className='custom-control-label'
                  htmlFor={`${checkbox.id}`}></label>
                <a href={checkbox.id} target='_blank' rel='noopener noreferrer'>
                  {checkbox.name}
                </a>
              </div>
            </CheckBoxes>
          )
        })}
    </div>
  )
}

const CheckBoxes = styled.div`
  display: flex;
  flex-direction: row;
`

Body.propTypes = {
  onCheckListChange: P.func,
  checkList: P.arrayOf(
    P.shape({
      id: P.string.isRequired,
      name: P.string.isRequired,
      checked: P.bool.isRequired,
    }),
  ),
}

Body.defaultProps = {
  onCheckListChange: () => {},
}

export default Body
