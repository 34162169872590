import React from 'react'
import styled from 'styled-components'

const NotFound = () => {
  return <Main>404: Not Found.</Main>
}

const Main = styled.code`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default NotFound
